import { Constants } from './constants'

export const cmds = {
    LS: { value: 'ls', help: Constants.ls_help },
    CAT: { value: 'cat', help: Constants.cat_help },
    WHOAMI: { value: 'whoami', help: Constants.whoami_help },
    DATE: { value: 'date', help: Constants.date_help },
    HELP: { value: 'help', help: Constants.help_help },
    CLEAR: { value: 'clear', help: Constants.clear_help },
    REBOOT: { value: 'reboot', help: Constants.reboot_help },
    CD: { value: 'cd', help: Constants.cd_help },
    MV: { value: 'mv', help: Constants.mv_help },
    RM: { value: 'rm', help: Constants.rm_help },
    RMDIR: { value: 'rmdir', help: Constants.rmdir_help },
    TOUCH: { value: 'touch', help: Constants.touch_help },
    SUDO: { value: 'sudo', help: Constants.sudo_help },
    NOP: { value: '', help: null }
}

import { Constants } from './lib/constants'
import { Terminal } from './terminal'

window.onload = (): void => new Terminal(
    document.getElementById('prompt-user') as HTMLElement,
    document.getElementById('prompt-host') as HTMLElement,
    document.getElementById('cmdline') as HTMLInputElement,
    document.getElementById('output') as HTMLElement,
    Constants.user,
    Constants.host,
    Constants.type_delay
).init()

import { InvalidArgumentException } from './exception/invalid-argument-exception'
import { files } from './files'
import { cmds } from './lib/cmds'
import { Constants } from './lib/constants'
import { ignoreEvent } from './lib/ignore-event'
import { isUsingIE } from './lib/is-using-ie'
import { scrollToBottom } from './lib/scroll-to-bottom'
import { TypeSimulator } from './type-simulator'

export class Terminal {
    cmdLine: HTMLInputElement
    promptUser: HTMLElement
    promptHost: HTMLElement
    output: HTMLElement
    host: string
    user: string
    typeSimulator: TypeSimulator
    completePrompt: string

    // eslint-disable-next-line max-params
    constructor(
        promptUser: HTMLElement,
        promptHost: HTMLElement,
        cmdLine: HTMLInputElement,
        output: HTMLElement,
        user: string,
        host: string,
        outputTimer: number
    ) {
        if (promptUser.nodeName.toUpperCase() !== 'SPAN') {
            throw new InvalidArgumentException(`Invalid value ${prompt} for argument 'prompt'.`)
        }
        if (promptHost.nodeName.toUpperCase() !== 'SPAN') {
            throw new InvalidArgumentException(`Invalid value ${prompt} for argument 'prompt'.`)
        }
        if (cmdLine.nodeName.toUpperCase() !== 'INPUT') {
            throw new InvalidArgumentException(`Invalid value ${cmdLine} for argument 'cmdLine'.`)
        }
        if (output.nodeName.toUpperCase() !== 'DIV') {
            throw new InvalidArgumentException(`Invalid value ${output} for argument 'output'.`)
        }
        this.user = user
        this.host = host
        this.completePrompt = `
        <span id="prompt-pre" class="prompt-blue">[</span>
        <span id="prompt-user" class="prompt-green">${this.user}</span>
        <span id="prompt-at" class="prompt-blue">@</span>
        <span id="prompt-host" class="prompt-white">${this.host}</span>
        <span id="prompt-suf" class="prompt-blue">]</span>
        <span id="prompt-dir" class="prompt-white">: ~</span>
        <span id="prompt-end" class="prompt-blue">></span>
        <span id="prompt-type" class="prompt-green">$</span>`

        this.promptUser = promptUser
        this.promptHost = promptHost
        this.cmdLine = cmdLine
        this.output = output
        this.typeSimulator = new TypeSimulator(outputTimer, output)
    }

    // eslint-disable-next-line promise/prefer-await-to-callbacks
    type(text: string): void {
        this.typeSimulator.type(text)
        this.unlock()
    }

    exec(): void {
        const command = this.cmdLine.value

        this.cmdLine.value = ''
        this.promptUser.textContent = ''
        this.promptHost.textContent = ''
        // this.output.innerHTML += `
        // <span class="prompt-color">asas${this.completePrompt}</span> ${command}<br/>`
        this.output.innerHTML += `${this.completePrompt} ${command}<br/>`
    }

    init(): void {
        this.cmdLine.disabled = true
        this.cmdLine.addEventListener('keydown', (event) => {
            if (event.which === 13 || event.keyCode === 13) {
                this.handleCmd()
                ignoreEvent(event)
            }
            else if (event.which === 9 || event.keyCode === 9) {
                this.handleFill()
                ignoreEvent(event)
            }
        })
        this.reset()
    }

    makeElementDisappear(element: HTMLElement) : void {
        element.style.opacity = '0'
        element.style.transform = 'translateX(-300px)'
    }

    makeElementAppear(element: HTMLElement) : void {
        element.style.opacity = '1'
        element.style.transform = 'translateX(0)'
    }

    lock() : void {
        this.exec()
        this.cmdLine.blur()
        this.cmdLine.disabled = true
    }

    unlock() : void {
        this.cmdLine.disabled = false
        this.promptHost.textContent = this.host
        this.promptUser.textContent = this.user
        scrollToBottom()
        this.focus()
    }

    handleFill() : void {
        const cmdComponents = this.cmdLine.value.trim().split(' ')

        if ((cmdComponents.length <= 1) || (cmdComponents.length === 2 && cmdComponents[0] === cmds.CAT.value)) {
            this.lock()
            const possibilities = []

            if (cmdComponents[0].toLowerCase() === cmds.CAT.value) {
                if (cmdComponents.length === 1) {
                    cmdComponents[1] = ''
                }
                // if (Constants.welcome_file_name.startsWith(cmdComponents[1].toLowerCase())) {
                //     possibilities.push(`${cmds.CAT.value} ${Constants.welcome_file_name}`)
                // }
                for (const file in files) {
                    if (file.startsWith(cmdComponents[1].toLowerCase())) {
                        possibilities.push(`${cmds.CAT.value} ${file}`)
                    }
                }
            }
            else {
                for (const command in cmds) {
                    if (cmds[command].value.startsWith(cmdComponents[0].toLowerCase())) {
                        possibilities.push(cmds[command].value)
                    }
                }
            }
            if (possibilities.length === 1) {
                this.cmdLine.value = `${possibilities[0]} `
                this.unlock()
            }
            else if (possibilities.length > 1) {
                this.type(possibilities.join('\n'))
                this.cmdLine.value = cmdComponents.join(' ')
            }
            else {
                this.cmdLine.value = cmdComponents.join(' ')
                this.unlock()
            }
        }
    }

    handleCmd() : void {
        const cmdComponents = this.cmdLine.value.trim().split(' ')

        this.lock()

        switch (cmdComponents[0]) {
            case cmds.CAT.value:
                this.cat(cmdComponents)
                break
            case cmds.LS.value:
                this.ls()
                break
            case cmds.WHOAMI.value:
                this.whoami()
                break
            case cmds.DATE.value:
                this.date()
                break
            case cmds.HELP.value:
                this.help()
                break
            case cmds.CLEAR.value:
                this.clear()
                break
            case cmds.REBOOT.value:
                this.reboot()
                break
            case cmds.CD.value:
            case cmds.MV.value:
            case cmds.RMDIR.value:
            case cmds.RM.value:
            case cmds.TOUCH.value:
                this.permissionDenied(cmdComponents)
                break
            case cmds.SUDO.value:
                this.sudo()
                break
            case cmds.NOP.value:
                this.emptyCommand()
                break
            default:
                this.invalidCommand(cmdComponents)
                break
        }
    }

    cat(cmdComponents: string[]) : void {
        let result

        if (cmdComponents.length <= 1) {
            result = `${Constants.usage}: ${cmds.CAT.value} <${Constants.file}>`
        }
        else if (!cmdComponents[1] || !Object.prototype.hasOwnProperty.call(files, cmdComponents[1])) {
            result = Constants.file_not_found.replace(Constants.value_token, cmdComponents[1])
        }
        else {
            result = files[cmdComponents[1]]
        }
        this.type(result)
    }

    ls() : void {
        let result = `.\n..\n${Constants.motd}\n`

        for (const file in files) {
            if (Object.prototype.hasOwnProperty.call(files, file)) {
                result += `${file}\n`
            }
        }
        this.type(result.trim())
    }

    sudo(): void {
        this.type(Constants.sudo_message)
    }

    whoami(): void {
        const result = `${Constants.username}: ${Constants.user}\n${Constants.hostname}: ${Constants.host}\n${Constants.platform}: ${navigator.platform}\n${Constants.accesible_cores}: ${navigator.hardwareConcurrency}\n${Constants.language}: ${navigator.language}`

        this.type(result)
    }

    date() : void {
        this.type(new Date().toString())
    }

    help(): void {
        let result = `${Constants.general_help}\n\n`

        for (const cmd in cmds) {
            if (Object.prototype.hasOwnProperty.call(cmds, cmd)) {
                result += `${cmds[cmd].value} - ${cmds[cmd].help}\n`
            }
        }
        this.type(result.trim())
    }

    clear(): void {
        this.output.textContent = ''
        // this.prompt.textContent = ''
        this.promptUser.textContent = this.user
        this.promptHost.textContent = this.host
        this.unlock()
    }

    reboot(): void {
        this.type(Constants.reboot_message)
    }

    reset(): void {
        this.output.textContent = ''
        this.promptUser.textContent = ''
        this.promptHost.textContent = ''
        if (this.typeSimulator) {
            this.type(Constants.motd + (isUsingIE ? `\n${Constants.internet_explorer_warning}` : ''))
        }
        this.unlock()
    }

    permissionDenied(cmdComponents: string[]): void {
        this.type(Constants.permission_denied_message.replace(Constants.value_token, cmdComponents[0]))
        this.unlock()
    }

    invalidCommand(cmdComponents: string[]): void {
        this.type(Constants.invalid_command_message.replace(Constants.value_token, cmdComponents[0]))
        this.unlock()
    }

    emptyCommand(): void {
        this.unlock()
    }

    focus(): void {
        this.cmdLine.focus()
    }
}

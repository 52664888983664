export class Constants {
    /* eslint-disable camelcase */
    static general_help = 'Below there\'s a list of commands that you can use.\nYou can use autofill by pressing the TAB key, autocompleting if there\'s only 1 possibility, or showing you a list of possibilities.'
    static ls_help = 'List information about the files and folders (the current directory by default).'
    static cat_help = 'Read FILE(s) content and print it to the standard output (screen).'
    static whoami_help = 'Print the user name associated with the current effective user ID and more info.'
    static date_help = 'Print the system date and time.'
    static help_help = 'Print this menu.'
    static clear_help = 'Clear the terminal screen.'
    static reboot_help = 'Reboot the system.'
    static cd_help = 'Change the current working directory.'
    static mv_help = 'Move (rename) files.'
    static rm_help = 'Remove files or directories.'
    static rmdir_help = 'Remove directory, this command will only work if the folders are empty.'
    static touch_help = 'Change file timestamps. If the file doesn\'t exist, it\'s created an empty one.'
    static sudo_help = 'Execute a command as the superuser.'
    static internet_explorer_warning = 'NOTE= I see you\'re using internet explorer, this website won\'t work properly.'
    static motd = ''
    static invalid_command_message = '<value>: command not found.'
    static reboot_message = 'Preparing to reboot...\n\n3...\n\n2...\n\n1...\n\nRebooting...\n\n'
    static permission_denied_message = 'Unable to \'<value>\', permission denied.'
    static sudo_message = 'Unable to sudo using a web client.'
    static usage = 'Usage'
    static file = 'file'
    static file_not_found = 'File \'<value>\' not found.'
    static username = 'Username'
    static hostname = 'Host'
    static platform = 'Platform'
    static accesible_cores = 'Accessible cores'
    static language = 'Language'
    static value_token = '<value>'
    static host = 'example.com'
    static user = 'guest'
    static is_root = false
    static type_delay = 20
    /* eslint-enable camelcase */
}

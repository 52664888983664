export class InvalidArgumentException extends Error {
    constructor(message: string) {
        super()
        this.message = message
        // Use V8's native method if available, otherwise fallback
        if ('captureStackTrace' in Error) {
            Error.captureStackTrace(this, InvalidArgumentException)
        }
        else {
            this.stack = new Error().stack
        }
    }
}

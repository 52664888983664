import { InvalidArgumentException } from './exception/invalid-argument-exception'
import { isURL } from './lib/is-url'
import { scrollToBottom } from './lib/scroll-to-bottom'

export class TypeSimulator {
    timer: number
    output: HTMLElement

    constructor(timer: number, output: HTMLElement) {
        if (timer < 0) {
            throw new InvalidArgumentException(`Invalid value ${timer} for argument 'timer'.`)
        }
        this.timer = timer
        this.output = output
    }

    // eslint-disable-next-line promise/prefer-await-to-callbacks
    type(text: string) : void {
        if (isURL(text)) {
            window.open(text)
        }
        let i = 0
        const { output } = this

        if (i < text.length) {
            const char = text.charAt(i)
            const isNewLine = char === '\n'

            output.innerHTML += isNewLine ? '<br/>' : char
            i++
            // eslint-disable-next-line prefer-regex-literals
            output.innerHTML += `${text.substring(i).replace(new RegExp('\\n', 'g'), '<br/>')}<br/>`
        }
        else {
            output.innerHTML += '<br/>'
        }
        scrollToBottom()
    }
}
